<template>
  <div>
    <v-row dense>
      <v-col
        v-for="(item, idx) in (events || items)"
        :key="item.o ? item.o.id : item.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <EventCard :item="item" :minimal="minimal" :showLogo="showLogos" :icon="icon" :routeResolver="routeResolver" :index="idx" @click="$listeners && $listeners.itemclick ? $emit('itemclick', item.id) : null"></EventCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import eventService from "@/services/eventService";
import EventCard from "@/components/EventCard.vue";

export default {
  name: "EventGrid",
  components: {
    EventCard
  },
  props: {
    items: Array,
    eventIds: Array,
    routeResolver: Function,
    minimal: Boolean,
    showLogos: Boolean,
    icon: String,
  },
  emits: {
    //itemclick
  },
  data() {
    return {
      events: null,
    };
  },
  async mounted() {
    if (this.eventIds && this.eventIds.length && !this.items) {
      this.events = (await eventService.getList(this.eventIds)).data.data;
    }
  },
  methods: {
  }
};
</script>
