<template>
  <v-container class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
        <v-card-title class="subtitle">Archived Events</v-card-title>
        <v-card-text class="pb-0">
          <v-btn text :to="{name: 'eventmanager'}" exact>All Events</v-btn>
        </v-card-text>
        <v-card-text class="pb-0">
          You can archive events as long as no participants have joined them.
        </v-card-text>

        <div v-if="events">
            <v-card-text class="pb-0" v-if="events.length == 0">
            <i class="mb-0">No archived events to show.</i>
            </v-card-text>      
            <EventGrid :routeResolver="resolveEventManagerRoute" :items="events" icon="fa-pencil-alt" :minimal="true" class="mx-4 mb-4"></EventGrid>

            <p>
                <v-btn color="primary" :to="{name:'eventmanagerCreate'}" class="ml-4">Create a new event</v-btn>
            </p>
            <br/>
        </div>
        <div v-else>
            <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
import EventUtil from "@/util/eventUtil";
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      events: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        //var response = await profileService.get(/*full:*/false);
        //this.profile = response.data;
        this.events = (await eventManagerService.getArchivedEvents()).data.data;
      }
    },

    resolveEventManagerRoute(eventId) {
      return {name: 'eventmanagerView', params: {id: eventId}}
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Archived Events', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

