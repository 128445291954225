<template>
    <v-card
      :href="redirectToExternalUrl ? data.external_url : null"
      :to="redirectToExternalUrl || hasClickListener ? null : (routeResolver ? routeResolver(data.id) : {name: 'event', params: {id: data.id}})"
      @click="hasClickListener ? $emit('click', data.id) : null"
      class="event-card"
      >
      <v-img
        v-if="data.img"
        :src="data.img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      > 
        <v-card v-if="showLogo && data.logo" color="white" class="pa-1 d-inline-block event-card--logo">
          <v-img :src="data.logo" height="80" width="80" class="" />
        </v-card>
        <v-card-title>{{data.name | trim(50)}}<v-icon v-if="icon" color="white" class="ml-2">{{icon}}</v-icon></v-card-title>
      </v-img>
      <v-sheet v-else :color="$helpers.getGradientColor(index)" height="200">
        <v-card-title>{{data.name}}<v-icon v-if="icon" class="ml-2">{{icon}}</v-icon></v-card-title></v-sheet>
      <div v-if="!minimal">
        <v-card-text v-if="$helpers.isActiveEvent(item)">
          <v-chip color="primary darken-0" class="white--text">Active now!</v-chip>
          till {{data.till | localDate("L")}}
        </v-card-text>
        <v-card-text v-else-if="$helpers.isFutureEvent(item)">
          <span class="py-1 d-inline-block">Opens in {{ $helpers.getStartDurationForEvent(item) | duration(true)}}</span>
        </v-card-text>
        <v-card-text v-else>
          <span class="py-1 d-inline-block">{{data.from | localDate("L")}} till {{data.till | localDate("L")}}</span>
        </v-card-text>
      </div>
    </v-card>
</template>

<script>
import Vue from "vue";

export default {
  name: "EventCard",
  props: {
    item: Object,
    route: Object,
    minimal: Boolean,
    showLogo: Boolean,
    icon: String,
    index: Number,
    routeResolver: Function,
  },
  emits: {
    //click
  },
  data: function() {
    return {
      data: null,
      score: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click && this.$listeners.click.length;
    },
    redirectToExternalUrl() {
      return this.item &&  !this.routeResolver && this.item.external_url && this.isFuture;
    },
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
  .event-card { min-height: 264px;}
  .event-card .event-card--logo { position:absolute; top: 10px; left: 10px; }
</style>